import React from 'react';
import "../assets/Styles/Infos.css";
import mainLogo from "../assets/Images/logo_open_challenge.svg"; // Logo principal de l'application
import { TbChevronLeft } from "react-icons/tb"
import { useNavigate } from "react-router-dom";


function Infos() {

    const navigate = useNavigate();
  return (
    <div className="infosContainer">
        <div className="infosWrapper">
        <div className="loginBanner">
      {/* Affiche le logo principal */}
      <TbChevronLeft className='backBtn' size={40} onClick={()=> {navigate(-1)}} />
      <img className="mainLogo" alt="logo_OC" src={mainLogo} />
      </div>
            <h2>Informations complémentaires</h2>
        </div>
    </div>
  )
}

export default Infos